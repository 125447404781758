/*
Template Name: Adminto Dashboard
Author: CoderThemes
Email: coderthemes@gmail.com
File: Pages
*/
/* =============
  == Pages List==

   - Calendar
   - Form Elements
   - Notification
   - Range slider
   - Sweet Alert
   - Charts
   - Timelines
   - Count down
   - Gallery
   - Maintenance
   - Maps
   - Tree view page
   - Tour page
   - Pricing
   - Taskboard & Task detail
   - Projects
   - Account pages
   - Inbox
   - Profile

============= */
/* =============
   Calendar
============= */
.calendar {
  float: left;
  margin-bottom: 0px;
}
.fc-view {
  margin-top: 30px;
}
.none-border .modal-footer {
  border-top: none;
}
.fc-toolbar {
  margin-bottom: 5px;
  margin-top: 15px;
}
.fc-toolbar h2 {
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  text-transform: uppercase;
}
.fc-day {
  background: #ffffff;
}
.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar button:focus,
.fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
  z-index: 0;
}
.fc-widget-header {
  border: 1px solid #ebeff2;
}
.fc-widget-content {
  border: 1px solid #ebeff2;
}
.fc th.fc-widget-header {
  background: #ebeff2;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 0px;
  text-transform: uppercase;
}
.fc-button {
  background: #ffffff;
  border: 1px solid #ebeff2;
  color: #555555;
  text-transform: capitalize;
}
.fc-text-arrow {
  font-family: inherit;
  font-size: 16px;
}
.fc-state-hover {
  background: #f3f3f3;
}
.fc-state-highlight {
  background: #f0f0f0;
}
.fc-cell-overlay {
  background: #f0f0f0;
}
.fc-unthemed .fc-today {
  background: #ffffff;
}
.fc-event {
  border-radius: 2px;
  border: none;
  cursor: move;
  font-size: 13px;
  margin: 5px 7px;
  padding: 5px 5px;
  text-align: center;
}
.external-event {
  color: #ffffff;
  cursor: move;
  margin: 10px 0;
  padding: 6px 10px;
}
.fc-basic-view td.fc-week-number span {
  padding-right: 5px;
}
.fc-basic-view td.fc-day-number {
  padding-right: 5px;
}
/* =============
   Form Advanced
============= */
.error {
  color: #ff5b5b;
  font-size: 12px;
  font-weight: 500;
}
/* X-Editable */
.editable-click,
a.editable-click,
a.editable-click:hover {
  border: none;
}
/* Bootstrap tagsinput */
.bootstrap-tagsinput {
  box-shadow: none;
  padding: 3px 7px 6px;
  border: 1px solid #e3e3e3;
}
.bootstrap-tagsinput .label-info {
  background-color: #71b6f9 !important;
  display: inline-block;
  padding: 5px;
}
/* Multiple */
.ms-container {
  background: transparent url('../images/multiple-arrow.png') no-repeat 50% 50%;
}
.ms-container .ms-list {
  box-shadow: none;
  border: 1px solid #e3e3e3;
}
.ms-container .ms-list.ms-focus {
  box-shadow: none;
  border: 1px solid #aaaaaa;
}
.ms-container .ms-selectable li.ms-elem-selectable {
  border: none;
  padding: 5px 10px;
}
.ms-container .ms-selection li.ms-elem-selection {
  border: none;
  padding: 5px 10px;
}
/* Select 2 */
.select2-container .select2-choice {
  background-image: none !important;
  border: none !important;
  height: auto  !important;
  padding: 0px !important;
  line-height: 22px !important;
  background-color: transparent !important;
  box-shadow: none !important;
}
.select2-container .select2-choice .select2-arrow {
  background-image: none !important;
  background: transparent;
  border: none;
  width: 14px;
  right: 5px;
  top: 2px;
}
.select2-container .select2-container-multi.form-control {
  height: auto;
}
.select2-results .select2-highlighted {
  color: #ffffff;
  background-color: #71b6f9;
}
.select2-drop-active {
  border: 1px solid #e3e3e3 !important;
  padding-top: 5px;
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
}
.select2-search input {
  border: 1px solid #e3e3e3;
}
.select2-container-multi {
  width: 100%;
}
.select2-container-multi .select2-choices {
  border: 1px solid #E3E3E3  !important;
  box-shadow: none !important;
  background-image: none  !important;
  -webkit-border-radius: 4px !important;
  border-radius: 4px !important;
  -moz-border-radius: 4px !important;
  background-clip: padding-box !important;
  min-height: 38px;
}
.select2-container-multi .select2-choices .select2-search-choice {
  padding: 4px 7px 4px 18px;
  margin: 5px 0 3px 5px;
  color: #555555;
  background: #f5f5f5;
  border-color: #e5e5e5;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.select2-container-multi .select2-choices .select2-search-field input {
  padding: 7px 7px 7px 10px;
  font-family: inherit;
}
.select2-chosen {
  height: 34px;
  line-height: 34px;
  padding-left: 15px;
}
/* Bootstrap-select */
.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  width: 100% !important;
}
.bootstrap-select .dropdown-toggle:focus {
  outline: none !important;
}
.bootstrap-select .glyphicon {
  padding-right: 6px;
}
/* Form validation */
.parsley-error {
  border-color: #ff5b5b !important;
}
.parsley-errors-list {
  display: none;
  margin: 0;
  padding: 0;
}
.parsley-errors-list.filled {
  display: block;
}
.parsley-errors-list > li {
  font-size: 12px;
  list-style: none;
  color: #ff5b5b;
}
/* Datepicker */
.datepicker {
  padding: 8px;
}
.datepicker th {
  font-size: 14px !important;
}
.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.selected,
.datepicker table tr td.selected:hover,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected.disabled:hover,
.datepicker table tr td span.active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover {
  background-image: none;
}
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active:hover.disabled,
.datepicker table tr td span.active.disabled.disabled,
.datepicker table tr td span.active.disabled:hover.disabled,
.datepicker table tr td span.active[disabled],
.datepicker table tr td span.active:hover[disabled],
.datepicker table tr td span.active.disabled[disabled],
.datepicker table tr td span.active.disabled:hover[disabled] {
  background-color: #71b6f9;
}
.datepicker table tr td.active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover {
  background-color: #71b6f9 !important;
  background-image: none;
  box-shadow: none;
  text-shadow: none;
}
.datepicker thead tr:first-child th:hover,
.datepicker tfoot tr th:hover {
  background-color: #fafafa;
}
.datepicker-inline {
  border: 2px solid #eeeeee;
}
.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #188ae2;
  border-color: #188ae2;
}
.daterangepicker .input-mini.active {
  border: 1px solid #AAAAAA;
}
.daterangepicker .ranges li {
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-border-radius: 2px;
  background-clip: padding-box;
  color: #435966;
  font-weight: 600;
  font-size: 12px;
}
.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect,
.daterangepicker select.ampmselect {
  border: 1px solid #e3e3e3;
  padding: 2px;
  width: 60px;
}
.daterangepicker .ranges li.active,
.daterangepicker .ranges li:hover {
  background-color: #188ae2;
  border: 1px solid #188ae2;
}
.search-input {
  margin-bottom: 10px;
}
.ms-selectable {
  box-shadow: none;
  outline: none !important;
}
.ms-container .ms-list.ms-focus {
  box-shadow: none;
}
.ms-container .ms-selectable li.ms-hover {
  background-color: #188ae2;
}
.ms-container .ms-selection li.ms-hover {
  background-color: #188ae2;
}
/* Timepicker */
.bootstrap-timepicker-widget table td a:hover {
  background-color: transparent;
  border-color: transparent;
  border-radius: 4px;
  color: #188ae2;
  text-decoration: none;
}
.bootstrap-timepicker-widget table td input {
  border: none;
}
/* Bootstrap-touchSpin */
.bootstrap-touchspin .input-group-btn-vertical .btn {
  padding: 9px 12px;
}
.bootstrap-touchspin .input-group-btn-vertical i {
  top: 4px;
  left: 8px;
}
/* Form wizard */
.navtab-wizard li:first-of-type a {
  border-left: none !important;
}
.navtab-wizard li:first-of-type a:hover,
.navtab-wizard li:first-of-type a:focus,
.navtab-wizard li:first-of-type a:active {
  border-left: none !important;
}
.navtab-wizard li:last-of-type a {
  border-right: none !important;
}
.navtab-wizard li:last-of-type a:hover,
.navtab-wizard li:last-of-type a:focus,
.navtab-wizard li:last-of-type a:active {
  border-right: none !important;
}
/* Wysiwig */
.mce-content-body p {
  color: #9398a0;
  font-size: 14px;
  font-weight: 300;
}
.mce-popover .mce-arrow:after {
  border-bottom-color: red;
}
.mce-popover .mce-colorbutton-grid {
  margin: 0px;
  border: 1px solid #d7dce5 !important;
  padding: 4px;
}
.mce-reset .mce-window-head {
  border-bottom: 1px solid #d7dce5;
}
.mce-reset .mce-window-head .mce-title {
  color: #707780;
  font-size: 16px;
  font-weight: 400;
}
.mce-reset .mce-textbox {
  border-radius: 0px;
  box-shadow: none;
  outline: 0;
  border-color: #d7dce5;
  height: 30px;
  font-weight: 300;
  line-height: 30px;
  color: #aaaaaa;
  font-size: 14px;
}
.mce-reset .mce-textbox:focus {
  box-shadow: none;
  border-color: #71b6f9;
}
.mce-reset .mce-checkbox .mce-ico {
  background-image: none;
  background-color: #ffffff;
  border-radius: 0px;
  border: 1px solid #d7dce5;
}
.mce-reset .mce-checkbox .mce-label {
  color: #707780;
  font-size: 12px;
  font-weight: 400;
}
.mce-container {
  border-radius: 0px !important;
  border-width: 0px !important;
}
.mce-container .mce-menubar {
  background-color: #f2f4f7 !important;
  border: 1px solid #d7dce5 !important;
  padding: 2px;
}
.mce-container .mce-menubar .mce-btn button span {
  color: #707780;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
.mce-container .mce-menubar .mce-btn button .mce-caret {
  border-top-color: #707780;
}
.mce-container .mce-menubar .mce-btn button:hover {
  background-color: #e8ebf1;
}
.mce-container .mce-menubar .mce-btn.mce-active button {
  background-color: #e8ebf1;
}
.mce-container .mce-btn {
  background-color: #d7dce5;
  background-image: none;
  outline: 0;
  border: 0px;
  border-radius: 0px;
}
.mce-container .mce-btn button {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  text-shadow: none;
}
.mce-container .mce-btn:hover {
  background-color: #b8c1d1;
  background-image: none;
}
.mce-container .mce-primary {
  background-color: #71b6f9;
  background-image: none;
  outline: 0;
  border: 0px;
  border-radius: 0px;
}
.mce-container .mce-primary button {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  text-shadow: none;
}
.mce-container .mce-primary:hover {
  background-color: #0c7cd5;
  background-image: none;
}
.mce-container .mce-toolbar-grp {
  background-color: #f2f4f7 !important;
  border: 1px solid #d7dce5 !important;
  border-top-width: 0px !important;
  padding: 6px;
}
.mce-container .mce-edit-area {
  border: 1px solid #d7dce5 !important;
  border-width: 0px 1px !important;
}
.mce-container .mce-statusbar {
  background-color: #f2f4f7 !important;
  border: 1px solid #d7dce5 !important;
}
.mce-container .mce-statusbar .mce-path .mce-path-item {
  color: #707780;
  font-size: 14px;
  font-weight: 400;
}
.mce-container .mce-widget {
  color: #9398a0;
  font-size: 14px;
  font-weight: 400;
  border-left: 1px solid transparent;
}
.mce-container .mce-btn-group {
  border: 1px solid #e9ecf2 !important;
}
.mce-container .mce-btn-group .mce-btn {
  box-shadow: none;
  background-image: none;
  background-color: #ffffff;
  border-width: 0px;
  border-radius: 0px !important;
}
.mce-container .mce-btn-group .mce-btn:hover,
.mce-container .mce-btn-group .mce-btn:focus {
  box-shadow: none;
  background-image: none;
  background-color: #ffffff;
}
.mce-container .mce-btn-group .mce-btn button span {
  color: #707780;
  font-size: 14px;
  font-weight: 300;
}
.mce-container .mce-btn-group .mce-btn button .mce-caret {
  color: #707780;
  font-size: 14px;
}
.mce-container .mce-ico {
  color: #707780;
  font-size: 14px;
}
.mce-container .mce-panel {
  background-image: none;
}
.mce-container.mce-menu {
  border: 1px solid #d7dce5 !important;
}
.mce-container.mce-menu .mce-menu-item {
  background-image: none;
}
.mce-container.mce-menu .mce-menu-item .mce-ico {
  color: #71b6f9;
  font-size: 14px;
}
.mce-container.mce-menu .mce-menu-item .mce-text {
  color: #707780;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
.mce-container.mce-menu .mce-menu-item .mce-menu-shortcut {
  color: #aaaaaa;
  font-size: 12px;
  font-weight: 300;
  text-transform: capitalize;
}
.mce-container.mce-menu .mce-menu-item:hover,
.mce-container.mce-menu .mce-menu-item:focus,
.mce-container.mce-menu .mce-menu-item.mce-selected {
  background-color: #71b6f9;
}
.mce-container.mce-menu .mce-menu-item:hover .mce-ico,
.mce-container.mce-menu .mce-menu-item:focus .mce-ico,
.mce-container.mce-menu .mce-menu-item.mce-selected .mce-ico,
.mce-container.mce-menu .mce-menu-item:hover .mce-text,
.mce-container.mce-menu .mce-menu-item:focus .mce-text,
.mce-container.mce-menu .mce-menu-item.mce-selected .mce-text,
.mce-container.mce-menu .mce-menu-item:hover .mce-menu-shortcut,
.mce-container.mce-menu .mce-menu-item:focus .mce-menu-shortcut,
.mce-container.mce-menu .mce-menu-item.mce-selected .mce-menu-shortcut {
  color: #ffffff;
}
.mce-container.mce-menu .mce-menu-item.mce-disabled .mce-ico,
.mce-container.mce-menu .mce-menu-item.mce-disabled .mce-text,
.mce-container.mce-menu .mce-menu-item.mce-disabled .mce-menu-shortcut {
  color: #aaaaaa;
}
.mce-container.mce-menu .mce-menu-item.mce-disabled:hover,
.mce-container.mce-menu .mce-menu-item.mce-disabled:focus,
.mce-container.mce-menu .mce-menu-item.mce-disabled.mce-selected {
  background-color: #d7dce5;
}
.mce-container.mce-menu .mce-menu-item.mce-disabled:hover .mce-ico,
.mce-container.mce-menu .mce-menu-item.mce-disabled:focus .mce-ico,
.mce-container.mce-menu .mce-menu-item.mce-disabled.mce-selected .mce-ico,
.mce-container.mce-menu .mce-menu-item.mce-disabled:hover .mce-text,
.mce-container.mce-menu .mce-menu-item.mce-disabled:focus .mce-text,
.mce-container.mce-menu .mce-menu-item.mce-disabled.mce-selected .mce-text,
.mce-container.mce-menu .mce-menu-item.mce-disabled:hover .mce-menu-shortcut,
.mce-container.mce-menu .mce-menu-item.mce-disabled:focus .mce-menu-shortcut,
.mce-container.mce-menu .mce-menu-item.mce-disabled.mce-selected .mce-menu-shortcut {
  color: #ffffff;
}
.mce-container.mce-menu .mce-menu-item-sep {
  background-color: #d7dce5;
}
.mce-container.mce-menu .mce-menu-item-sep:hover {
  background-color: #d7dce5;
}
.mce-menubtn button {
  color: #435966 !important;
}
.mce-menu-item-normal.mce-active {
  background-color: #71b6f9 !important;
}
.mce-menu-item-normal.mce-active .mce-text {
  color: #ffffff !important;
}
/* =============
   Notification
============= */
#toast-container > div {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  opacity: 1;
}
#toast-container > :hover {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  opacity: 0.9;
}
.toast {
  background-color: #71b6f9;
}
.toast-success {
  background-color: rgba(16, 196, 105, 0.8);
  border: 2px solid #10c469;
}
.toast-error {
  background-color: rgba(255, 91, 91, 0.8);
  border: 2px solid #ff5b5b;
}
.toast-info {
  background-color: rgba(53, 184, 224, 0.8);
  border: 2px solid #35b8e0;
}
.toast-warning {
  background-color: rgba(249, 200, 81, 0.8);
  border: 2px solid #f9c851;
}
/* =========== */
/* Sweet Alert */
/* =========== */
.sweet-alert p {
  font-size: 14px;
  line-height: 22px;
}
.sweet-alert .icon.success .placeholder {
  border: 4px solid rgba(16, 196, 105, 0.3);
}
.sweet-alert .icon.success .line {
  background-color: #10c469;
}
.sweet-alert .icon.warning {
  border-color: #f9c851;
}
.sweet-alert .icon.info {
  border-color: #35b8e0;
}
.sweet-alert .btn-warning:focus,
.sweet-alert .btn-info:focus,
.sweet-alert .btn-success:focus,
.sweet-alert .btn-danger:focus,
.sweet-alert .btn-default:focus {
  box-shadow: none;
}
/* =============
   Charts
============= */
/* Morris chart */
.morris-hover.morris-default-style {
  border-radius: 5px;
  padding: 10px 12px;
  background: #435966;
  border: none;
  color: #ffffff !important;
}
.morris-hover.morris-default-style .morris-hover-point {
  color: #f4f8fb !important;
}
/* Morris chart Title */
.chart-detail-list li {
  margin: 0px 10px;
}
.chart-detail-list li h5 {
  font-weight: 600;
}
.pieLabel div {
  font-size: 14px !important;
}
.jqstooltip {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.chart {
  position: relative;
  display: inline-block;
  width: 110px;
  height: 110px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}
.chart canvas {
  position: absolute;
  top: 0;
  left: 0;
}
.chart.chart-widget-pie {
  margin-top: 5px;
  margin-bottom: 5px;
}
.percent {
  display: inline-block;
  line-height: 110px;
  z-index: 2;
  font-weight: 600;
  font-size: 18px;
  color: #435966;
}
.percent:after {
  content: '%';
  margin-left: 0.1em;
  font-size: .8em;
}
/* Flot chart */
#flotTip {
  padding: 8px 12px;
  background-color: #435966;
  z-index: 100;
  color: #ffffff;
  opacity: 0.9;
  font-size: 13px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.legend tr {
  height: 20px;
}
.legendLabel {
  padding-left: 5px !important;
  line-height: 10px;
  padding-right: 10px;
}
/* Sparkline chart */
.jqstooltip {
  background-color: #435966 !important;
  padding: 5px 10px !important;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  border-color: #435966 !important;
}
.jqsfield {
  font-size: 12px !important;
  line-height: 18px !important;
}
/* Chartist chart */
.ct-golden-section:before {
  float: none;
}
.ct-chart {
  max-height: 300px;
}
.ct-chart .ct-label {
  fill: #a3afb7;
  color: #a3afb7;
  font-size: 12px;
  line-height: 1;
}
.ct-grid {
  stroke: rgba(67, 89, 102, 0.15);
}
.ct-chart.simple-pie-chart-chartist .ct-label {
  color: #ffffff;
  fill: #ffffff;
  font-size: 16px;
}
.ct-chart .ct-series.ct-series-a .ct-bar,
.ct-chart .ct-series.ct-series-a .ct-line,
.ct-chart .ct-series.ct-series-a .ct-point,
.ct-chart .ct-series.ct-series-a .ct-slice-donut {
  stroke: #5b69bc;
}
.ct-chart .ct-series.ct-series-b .ct-bar,
.ct-chart .ct-series.ct-series-b .ct-line,
.ct-chart .ct-series.ct-series-b .ct-point,
.ct-chart .ct-series.ct-series-b .ct-slice-donut {
  stroke: #ff8acc;
}
.ct-chart .ct-series.ct-series-c .ct-bar,
.ct-chart .ct-series.ct-series-c .ct-line,
.ct-chart .ct-series.ct-series-c .ct-point,
.ct-chart .ct-series.ct-series-c .ct-slice-donut {
  stroke: #f9c851;
}
.ct-chart .ct-series.ct-series-d .ct-bar,
.ct-chart .ct-series.ct-series-d .ct-line,
.ct-chart .ct-series.ct-series-d .ct-point,
.ct-chart .ct-series.ct-series-d .ct-slice-donut {
  stroke: #35b8e0;
}
.ct-chart .ct-series.ct-series-e .ct-bar,
.ct-chart .ct-series.ct-series-e .ct-line,
.ct-chart .ct-series.ct-series-e .ct-point,
.ct-chart .ct-series.ct-series-e .ct-slice-donut {
  stroke: #435966;
}
.ct-chart .ct-series.ct-series-f .ct-bar,
.ct-chart .ct-series.ct-series-f .ct-line,
.ct-chart .ct-series.ct-series-f .ct-point,
.ct-chart .ct-series.ct-series-f .ct-slice-donut {
  stroke: #188ae2;
}
.ct-chart .ct-series.ct-series-g .ct-bar,
.ct-chart .ct-series.ct-series-g .ct-line,
.ct-chart .ct-series.ct-series-g .ct-point,
.ct-chart .ct-series.ct-series-g .ct-slice-donut {
  stroke: #10c469;
}
.ct-series-a .ct-area,
.ct-series-a .ct-slice-pie {
  fill: #188ae2;
}
.ct-series-b .ct-area,
.ct-series-b .ct-slice-pie {
  fill: #ff8acc;
}
.ct-series-c .ct-area,
.ct-series-c .ct-slice-pie {
  fill: #35b8e0;
}
.ct-series-d .ct-area,
.ct-series-d .ct-slice-pie {
  fill: #71b6f9;
}
.chartist-tooltip {
  position: absolute;
  display: inline-block;
  opacity: 0;
  min-width: 10px;
  padding: 2px 10px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  background-clip: padding-box;
  background: #435966;
  color: #ffffff;
  text-align: center;
  pointer-events: none;
  z-index: 1;
  -webkit-transition: opacity .2s linear;
  -moz-transition: opacity .2s linear;
  -o-transition: opacity .2s linear;
  transition: opacity .2s linear;
}
.chartist-tooltip.tooltip-show {
  opacity: 1;
}
/* Circliful charts */
.circliful-chart {
  margin: 0px auto;
}
.circle-text,
.circle-info,
.circle-text-half,
.circle-info-half {
  font-size: 12px;
  font-weight: 600;
}
/* =============
   Count Down
============= */
.home-wrapper {
  margin: 10% 0px;
}
.home-text {
  font-weight: 600;
}
#count-down {
  margin-top: 50px;
}
#count-down .clock-presenter {
  height: 140px;
  line-height: 30px;
  padding: 0px 30px;
  text-align: center;
}
#count-down .clock-presenter .digit {
  margin-top: 20px;
  font-size: 60px;
  line-height: 60px;
  height: 60px;
  display: inline-block;
  overflow: hidden;
  text-align: center;
  position: relative;
  cursor: default;
}
#count-down .clock-presenter .note {
  position: relative;
  bottom: 0px;
  padding-top: 5px;
  cursor: default;
  font-size: 16px;
  opacity: 0.7;
}
@media (max-width: 992px) {
  #count-down .clock-presenter .digit {
    font-size: 42px;
  }
}
@media (max-width: 767px) {
  #count-down .clock-presenter {
    width: 50%;
    float: left;
  }
  #count-down .clock-presenter .digit {
    font-size: 36px;
  }
  #count-down .hours_dash {
    border-right: none;
  }
}
/* =============
   Timeline
============= */
.timeline {
  border-collapse: collapse;
  border-spacing: 0;
  display: table;
  margin-bottom: 50px;
  position: relative;
  table-layout: fixed;
  width: 100%;
}
.timeline .time-show {
  margin-bottom: 30px;
  margin-right: -75px;
  margin-top: 30px;
  position: relative;
}
.timeline .time-show a {
  color: #ffffff;
}
.timeline:before {
  background-color: rgba(152, 166, 173, 0.3);
  bottom: 0px;
  content: "";
  left: 50%;
  position: absolute;
  top: 30px;
  width: 1px;
  z-index: 0;
}
.timeline .timeline-icon {
  -webkit-border-radius: 50%;
  background: #98a6ad;
  border-radius: 50%;
  color: #ffffff;
  display: block;
  height: 20px;
  left: -54px;
  margin-top: -10px;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 20px;
}
.timeline .timeline-icon i {
  color: #ebeff2;
}
.timeline .time-icon:before {
  font-size: 16px;
  margin-top: 5px;
}
h3.timeline-title {
  color: #98a6ad;
  font-size: 20px;
  font-weight: 400;
  margin: 0 0 5px;
  text-transform: uppercase;
}
.timeline-item {
  display: table-row;
}
.timeline-item:before {
  content: "";
  display: block;
  width: 50%;
}
.timeline-item .timeline-desk .arrow {
  border-bottom: 8px solid transparent;
  border-right: 8px solid #ffffff !important;
  border-top: 8px solid transparent;
  display: block;
  height: 0;
  left: -7px;
  margin-top: -10px;
  position: absolute;
  top: 50%;
  width: 0;
}
.timeline-item.alt:after {
  content: "";
  display: block;
  width: 50%;
}
.timeline-item.alt .timeline-desk .arrow-alt {
  border-bottom: 8px solid transparent;
  border-left: 8px solid #ffffff !important;
  border-top: 8px solid transparent;
  display: block;
  height: 0;
  left: auto;
  margin-top: -10px;
  position: absolute;
  right: -7px;
  top: 50%;
  width: 0;
}
.timeline-item.alt .timeline-desk .album {
  float: right;
  margin-top: 20px;
}
.timeline-item.alt .timeline-desk .album a {
  float: right;
  margin-left: 5px;
}
.timeline-item.alt .timeline-icon {
  left: auto;
  right: -56px;
}
.timeline-item.alt:before {
  display: none;
}
.timeline-item.alt .panel {
  margin-left: 0;
  margin-right: 45px;
}
.timeline-item.alt .panel .panel-body p + p {
  margin-top: 10px !important;
}
.timeline-item.alt h4 {
  text-align: right;
}
.timeline-item.alt p {
  text-align: right;
}
.timeline-item.alt .timeline-date {
  text-align: right;
}
.timeline-desk {
  display: table-cell;
  vertical-align: top;
  width: 50%;
}
.timeline-desk h4 {
  font-size: 16px;
  font-weight: 300;
  margin: 0;
}
.timeline-desk .panel {
  background: #ffffff;
  display: block;
  margin-bottom: 5px;
  margin-left: 45px;
  position: relative;
  text-align: left;
}
.timeline-desk h5 span {
  color: #797979;
  display: block;
  font-size: 12px;
  margin-bottom: 4px;
}
.timeline-desk p {
  color: #999999;
  font-size: 14px;
  margin-bottom: 0;
}
.timeline-desk .album {
  margin-top: 12px;
}
.timeline-desk .album a {
  float: left;
  margin-right: 5px;
}
.timeline-desk .album img {
  height: 36px;
  width: auto;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  background-clip: padding-box;
}
.timeline-desk .notification {
  background: none repeat scroll 0 0 #ffffff;
  margin-top: 20px;
  padding: 8px;
}
/* =============
   Gallery
============= */
.portfolioFilter a {
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  color: #333333;
  font-weight: 600;
  padding: 5px 10px;
  display: inline-block;
  margin-bottom: 5px;
}
.portfolioFilter a:hover {
  color: #71b6f9;
}
.portfolioFilter a.current {
  color: #71b6f9;
}
.thumb {
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  width: 100%;
}
.thumb-img {
  border-radius: 2px;
  overflow: hidden;
  width: 100%;
}
.gal-detail h4 {
  margin-top: 16px;
  font-weight: 600;
  font-size: 16px;
  padding: 0px 5px;
}
.gal-detail p {
  padding: 0px 5px;
  font-size: 13px;
}
/* =============
   Maintenance
============= */
.icon-main {
  font-size: 88px;
  margin-bottom: 50px;
}
.maintenance-page {
  margin: 10% 0%;
}
/* =============
   Maps
============= */
.gmaps,
.gmaps-panaroma {
  height: 300px;
  background: #eeeeee;
  border-radius: 3px;
}
.gmaps-overlay {
  display: block;
  text-align: center;
  color: #ffffff;
  font-size: 16px;
  line-height: 40px;
  background: #71b6f9;
  border-radius: 4px;
  padding: 10px 20px;
}
.gmaps-overlay_arrow {
  left: 50%;
  margin-left: -16px;
  width: 0;
  height: 0;
  position: absolute;
}
.gmaps-overlay_arrow.above {
  bottom: -15px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-top: 16px solid #71b6f9;
}
.gmaps-overlay_arrow.below {
  top: -15px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-bottom: 16px solid #71b6f9;
}
.jvectormap-zoomin,
.jvectormap-zoomout {
  width: 10px;
  height: 10px;
  line-height: 10px;
}
.jvectormap-zoomout {
  top: 40px;
}
/* =============
   Tree view page
============= */
.jstree-default .jstree-clicked {
  background: rgba(113, 182, 249, 0.4);
  box-shadow: none;
}
.jstree-default .jstree-hovered {
  background: rgba(113, 182, 249, 0.2);
  box-shadow: none;
}
.jstree-default .jstree-wholerow-clicked {
  background: rgba(113, 182, 249, 0.4);
}
.jstree-default .jstree-wholerow-hovered {
  background: rgba(113, 182, 249, 0.2);
}
.jstree-default .zmdi {
  font-size: 16px;
}
/* =============
   Tour page
============= */
div.hopscotch-bubble {
  border: 3px solid #188ae2;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  background-clip: padding-box;
}
div.hopscotch-bubble .hopscotch-next {
  background-color: #188ae2 !important;
  background-image: none !important;
  border-color: #188ae2 !important;
  text-shadow: none !important;
  margin: 0 0 0 5px !important;
}
div.hopscotch-bubble .hopscotch-prev {
  background-color: #188ae2 !important;
  background-image: none !important;
  border-color: #188ae2 !important;
  text-shadow: none !important;
  color: #ffffff !important;
}
div.hopscotch-bubble .hopscotch-bubble-number {
  background: #71b6f9;
  padding: 0px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -moz-border-radius: 50%;
  background-clip: padding-box;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.left .hopscotch-bubble-arrow-border {
  border-right: 19px solid #188ae2;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.right .hopscotch-bubble-arrow {
  border-left: 19px solid #188ae2;
  left: -2px;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.left .hopscotch-bubble-arrow {
  border: none;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.right .hopscotch-bubble-arrow-border {
  border-left: 0px solid #188ae2;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.up .hopscotch-bubble-arrow {
  border-bottom: 19px solid #188ae2;
  top: 0px;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.up .hopscotch-bubble-arrow-border {
  border-bottom: 0px solid rgba(0, 0, 0, 0.5);
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.down .hopscotch-bubble-arrow {
  border-top: 19px solid #188ae2;
  top: -2px;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.down .hopscotch-bubble-arrow-border {
  border-top: 0px solid rgba(0, 0, 0, 0.5);
}
/* =============
   Pricing
============= */
.pricing-column {
  position: relative;
  margin-bottom: 40px;
}
.pricing-column .inner-box {
  position: relative;
  padding: 0px 0px 50px;
}
.pricing-column .plan-header {
  position: relative;
  padding: 30px 20px 25px;
}
.pricing-column .plan-title {
  font-size: 16px;
  font-family: 'Karla', sans-serif;
  margin-bottom: 10px;
  color: #188ae2;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
}
.pricing-column .plan-price {
  font-size: 48px;
  font-family: 'Karla', sans-serif;
  margin-bottom: 10px;
  color: #435966;
}
.pricing-column .plan-duration {
  font-size: 13px;
  color: #98a6ad;
}
.pricing-column .plan-stats {
  position: relative;
  padding: 30px 20px 15px;
}
.pricing-column .plan-stats li {
  margin-bottom: 15px;
  line-height: 24px;
}
.ribbon {
  position: absolute;
  left: 5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
}
.ribbon span {
  font-size: 10px;
  font-weight: bold;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  width: 100px;
  display: block;
  box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);
  background: #79A70A;
  background: linear-gradient(#3bafda 0%, #3bafda 100%);
  position: absolute;
  top: 19px;
  left: -21px;
}
.ribbon span:before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #3bafda;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #3bafda;
}
.ribbon span:after {
  content: "";
  position: absolute;
  right: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #3bafda;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #3bafda;
}
/* =============
   Taskboard & Detail
============= */
.taskboard-box ul.task-list {
  min-height: 50px;
}
.kanban-box .checkbox-wrapper {
  float: left;
}
.kanban-box .checkbox-wrapper .checkbox {
  margin-top: 3px;
}
.kanban-box .kanban-detail {
  margin-left: 35px;
}
.kanban-box .kanban-detail h4 {
  margin-top: 0px;
  font-size: 15px;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
}
.kanban-box .kanban-detail h4 a {
  color: #435966;
}
.kanban-box .kanban-detail ul li a {
  display: inline-block;
}
.kanban-box .kanban-detail ul li a i {
  font-size: 16px;
  color: #98a6ad;
  padding: 0px 5px;
  line-height: 32px;
}
.gu-mirror {
  box-shadow: 0 0px 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);
  border: 1px solid rgba(67, 89, 102, 0.1) !important;
  margin-bottom: 0px !important;
  opacity: 1;
}
.gu-mirror .card-box {
  margin-bottom: 0px !important;
}
/* Task Detail */
.task-detail .task-dates li {
  width: 50%;
  float: left;
}
.task-detail .task-tags .bootstrap-tagsinput {
  padding: 0px;
  border: none;
}
.task-detail .assign-team a {
  display: inline-block;
  margin: 5px 5px 5px 0px;
}
.task-detail .files-list .file-box {
  display: inline-block;
  vertical-align: middle;
  width: 80px;
  padding: 2px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  background-clip: padding-box;
}
.task-detail .files-list .file-box img {
  line-height: 70px;
}
.task-detail .files-list .file-box p {
  width: 100%;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/* =============
   Projects
============= */
.project-sort-item .form-group {
  margin-right: 30px;
  display: inline-block;
}
.project-sort-item .form-group:last-of-type {
  margin-right: 0px;
}
.project-box {
  position: relative;
}
.project-box .label {
  position: absolute;
  right: 20px;
}
.project-box h4 {
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  width: 100%;
  overflow: hidden;
  font-weight: 600;
}
.project-box ul li {
  padding-right: 30px;
}
.project-box .project-members a {
  margin: 3px;
  display: inline-block;
}
.project-box .project-members a:first-of-type {
  margin-left: 0px;
}
/* =============
   Accounts pages
============= */
.account-pages {
  background: url("/img/banners/login-image.png") center;
  position: absolute;
  height: 100%;
  width: 100%;
  /*background-size: cover;*/
  /* aaw resize */
  background-size: 100% 100%;
  border-bottom: 200px solid #33C36E;
}
.wrapper-page {
  margin: 5% auto;
  position: relative;
  width: 420px;
}
.wrapper-page .card-box {
  box-shadow: 0 0px 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);
}
.wrapper-page .logo {
  font-size: 42px;
}
.panel-pages {
  border-radius: 6px;
}
.panel-pages .panel-body {
  padding: 30px;
}
.panel-pages .panel-heading {
  -moz-border-radius: 6px 6px 0px 0px;
  -webkit-border-radius: 6px 6px 0px 0px;
  border-radius: 6px 6px 0px 0px;
  padding: 40px 20px;
  position: relative;
}
.panel-pages .panel-heading h3 {
  position: relative;
  z-index: 999;
}
.user-thumb {
  position: relative;
  z-index: 999;
}
.user-thumb img {
  height: 88px;
  margin: 0px auto;
  width: 88px;
}
.ex-page-content .text-error {
  color: #71b6f9;
  text-shadow: rgba(113, 182, 249, 0.3) 5px 1px, rgba(113, 182, 249, 0.2) 12px 3px, rgba(113, 182, 249, 0.3) 6px 4px;
  font-size: 98px;
  font-weight: 700;
  line-height: 150px;
}
.ex-page-content .text-error i {
  font-size: 78px;
  padding: 0px 10px;
}
/* INBOX */
.inbox-app-main {
  margin-left: -20px;
}
.circle-icon {
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 28px;
  border: 2px solid #98a6ad;
  border-radius: 100px;
  font-size: 14px;
  color: #98a6ad;
  cursor: pointer;
  display: block;
  float: left;
}
.circle-icon:hover {
  color: #6e818a;
  border-color: #6e818a;
}
.circle-icon.small {
  height: 25px;
  width: 25px;
  line-height: 23px;
  font-size: 11px;
}
.circle-icon.red {
  color: #ff5b5b;
  border-color: #ff5b5b;
}
.circle-icon.red:hover {
  color: #c10000;
  border-color: #c10000;
}
.checkbox-wrapper-mail {
  cursor: pointer;
  height: 20px;
  width: 20px;
  position: relative;
  display: inline-block;
  box-shadow: inset 0 0 0 1px #98a6ad;
  border-radius: 1px;
}
.checkbox-wrapper-mail input {
  opacity: 0;
  cursor: pointer;
}
.checkbox-wrapper-mail input:checked ~ label {
  opacity: 1;
}
.checkbox-wrapper-mail label {
  position: absolute;
  top: 3px;
  left: 3px;
  right: 3px;
  bottom: 3px;
  cursor: pointer;
  background: #98a6ad;
  opacity: 0;
  margin-bottom: 0px !important;
  transition-duration: .05s;
}
.checkbox-wrapper-mail label:active {
  background: #87949b;
}
#sidebar {
  position: fixed;
  height: 100%;
  width: 240px;
  transition-duration: .3s;
  z-index: 5;
  color: #435966;
}
#sidebar a {
  color: #435966;
}
#sidebar .menu-segment {
  padding: 0 50px;
}
#sidebar .menu-segment ul,
#sidebar .menu-segment li {
  margin: 0;
  padding: 0;
}
#sidebar .menu-segment .ball.pink {
  border-color: #ff8acc;
}
#sidebar .menu-segment .ball.green {
  border-color: #10c469;
}
#sidebar .menu-segment .ball.blue {
  border-color: #188ae2;
}
#sidebar .menu-segment li.title {
  text-transform: uppercase;
  font-weight: 600;
  padding: 10px 0;
  color: #465162;
}
#sidebar .menu-segment li.title .icon {
  float: right;
}
#sidebar .menu-segment li.active a,
#sidebar .menu-segment li.active a:hover {
  color: #ff5b5b;
  font-weight: 600;
}
#sidebar .menu-segment li a {
  display: block;
  margin: 0 -50px;
  padding: 10px 50px;
}
#sidebar .menu-segment li a:hover {
  background: rgba(152, 166, 173, 0.3);
}
#sidebar .menu-segment li .italic-link {
  font-style: italic;
  font-weight: 600;
}
#sidebar .menu-segment .labels li a span,
#sidebar .menu-segment .chat li a span {
  display: block;
  border: 5px solid #ff5b5b;
  border-radius: 100px;
  margin-top: 6px;
}
#sidebar .menu-segment .labels.labels li a span,
#sidebar .menu-segment .chat.labels li a span {
  float: right;
}
#sidebar .menu-segment .labels.chat li a span,
#sidebar .menu-segment .chat.chat li a span {
  float: left;
  margin-right: 10px;
}
#sidebar .menu-segment .chat li a {
  padding-left: 50px;
}
#sidebar .separator {
  margin: 30px 35px;
  height: 1px;
  background: rgba(152, 166, 173, 0.5);
}
#sidebar .bottom-padding {
  height: 100px;
}
#sidebar > .nano-pane {
  background: transparent;
  width: 5px;
}
#sidebar > .nano-pane .nano-slider {
  background: rgba(152, 166, 173, 0.3);
}
#main {
  position: fixed;
  top: 72px;
  left: 490px;
  bottom: 0;
  right: 0;
  z-index: 4;
  transition-duration: .3s;
  background-color: #ffffff;
}
#main .overlay {
  position: absolute;
  top: 0;
  left: -5px;
  right: 0;
  bottom: 100%;
  background: rgba(67, 89, 102, 0.6);
  z-index: 5;
  opacity: 0;
  transition-duration: 0s;
  transition-property: opacity;
}
#main .header {
  padding: 20px 25px;
  border-bottom: 1px solid rgba(152, 166, 173, 0.3);
  overflow: hidden;
}
#main .header .page-title {
  display: block;
  float: left;
}
#main .header .page-title .sidebar-toggle-btn {
  width: 0;
  margin-top: 1px;
  padding: 11px 0 0 0;
  float: left;
  position: relative;
  display: block;
  cursor: pointer;
  transition-duration: .3s;
  transition-delay: .5s;
  opacity: 0;
  margin-right: 0;
}
#main .header .page-title .sidebar-toggle-btn .line {
  height: 3px;
  display: block;
  background: #888;
  margin-bottom: 4px;
  transition-duration: .5s;
  transition-delay: .5s;
}
#main .header .page-title .sidebar-toggle-btn .line-angle1 {
  width: 8px;
  margin: 0;
  position: absolute;
  top: 15px;
  left: -11px;
  transform: rotate(-60deg);
}
#main .header .page-title .sidebar-toggle-btn .line-angle2 {
  width: 8px;
  margin: 0;
  position: absolute;
  top: 21px;
  left: -11px;
  transform: rotate(60deg);
}
#main .header .page-title .icon {
  font-size: 15px;
  margin-left: 20px;
  position: relative;
  top: -5px;
  cursor: pointer;
}
#main .header .search-box {
  width: 180px;
  height: 40px;
  margin-left: 30px;
  position: relative;
}
#main .header .search-box input {
  position: absolute;
  top: 0;
  transition-duration: .3s;
  right: 0;
  bottom: 0;
  width: 100%;
  padding: 0;
  margin: 0;
  text-indent: 15px;
  height: 40px;
  z-index: 2;
  outline: none;
  color: #999;
  background: transparent;
  border: 2px solid #EFEFEF;
  border-radius: 5px;
  transition-timing-function: cubic-bezier(0.3, 1.5, 0.6, 1);
}
#main .header .search-box input:focus {
  color: #333;
  border-color: #d6d6d6;
}
#main .header .search-box input:focus ~ .icon {
  opacity: 1;
  z-index: 3;
  color: #10c469;
}
#main .header .search-box .icon {
  transition-duration: .3s;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 40px;
  text-align: center;
  line-height: 40px;
  z-index: 1;
  cursor: pointer;
  opacity: .5;
}
#main .action-bar {
  padding: 5px;
  overflow: hidden;
}
#main .message-list {
  display: block;
  padding-left: 0px;
}
#main .message-list li {
  position: relative;
  display: block;
  height: 50px;
  line-height: 50px;
  cursor: default;
  transition-duration: .3s;
}
#main .message-list li:hover {
  background: rgba(152, 166, 173, 0.15);
  transition-duration: .05s;
}
#main .message-list li .col {
  float: left;
  position: relative;
}
#main .message-list li .col-1 {
  width: 400px;
}
#main .message-list li .col-1 .star-toggle,
#main .message-list li .col-1 .checkbox-wrapper-mail,
#main .message-list li .col-1 .dot {
  display: block;
  float: left;
}
#main .message-list li .col-1 .dot {
  border: 4px solid transparent;
  border-radius: 100px;
  margin: 22px 26px 0;
  height: 0;
  width: 0;
  line-height: 0;
  font-size: 0;
}
#main .message-list li .col-1 .checkbox-wrapper-mail {
  margin-top: 15px;
  margin-right: 10px;
}
#main .message-list li .col-1 .star-toggle {
  margin-top: 18px;
  font-size: 16px;
  margin-left: 5px;
}
#main .message-list li .col-1 .title {
  position: absolute;
  top: 15px;
  left: 140px;
  right: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
#main .message-list li .col-2 {
  position: absolute;
  top: 0;
  left: 400px;
  right: 0;
  bottom: 0;
}
#main .message-list li .col-2 .subject,
#main .message-list li .col-2 .date {
  position: absolute;
  top: 0;
}
#main .message-list li .col-2 .subject {
  left: 0;
  right: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
#main .message-list li .col-2 .date {
  right: 0;
  width: 200px;
  padding-left: 80px;
}
#main .message-list li.active,
#main .message-list li.selected {
  background: rgba(152, 166, 173, 0.15);
  transition-duration: .05s;
}
#main .message-list li.active,
#main .message-list li.active:hover {
  box-shadow: inset 3px 0 0 #71b6f9;
}
#main .message-list li.unread {
  font-weight: 600;
  color: #555;
}
#main .message-list li.blue-dot .col-1 .dot {
  border-color: #188ae2;
}
#main .message-list li.orange-dot .col-1 .dot {
  border-color: #f9c851;
}
#main .message-list li.green-dot .col-1 .dot {
  border-color: #10c469;
}
#main .load-more-link {
  display: block;
  text-align: center;
  margin: 30px 0 50px 0;
}
.show-main-overlay #main .overlay {
  opacity: 1;
  bottom: 0;
  transition-duration: .5s;
}
.show-sidebar #main .header .page-title .sidebar-toggle-btn {
  transition-delay: 0s;
}
.show-sidebar #main .header .page-title .sidebar-toggle-btn .line-angle1 {
  transform: rotate(-120deg);
}
.show-sidebar #main .header .page-title .sidebar-toggle-btn .line-angle2 {
  transform: rotate(120deg);
}
#main #main-nano-wrapper {
  position: absolute;
  top: 100px;
  bottom: 0;
  height: auto;
}
#message {
  position: fixed;
  top: 72px;
  left: 60%;
  bottom: 0;
  width: 40%;
  z-index: 5;
  transform: translateX(200%);
  transition-duration: .5s;
  padding: 20px 30px;
  background: #ffffff;
  box-shadow: 0 0px 77px 0 rgba(0, 0, 0, 0.08), 0 1px 0px 0 rgba(0, 0, 0, 0.02);
}
#message .header {
  padding-bottom: 20px;
  border-bottom: 2px solid rgba(152, 166, 173, 0.2);
}
#message .header .page-title {
  display: block;
  float: none;
  margin-bottom: 15px;
  line-height: 40px;
}
#message .header .page-title .icon {
  margin-top: 4px;
  margin-right: 10px;
}
#message .header .grey {
  margin-left: 10px;
  color: #999;
}
#message .message-container {
  padding: 0 30px;
}
#message .message-container li {
  padding: 25px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: #FFF;
  margin: 0 0 30px 0;
  position: relative;
}
#message .message-container li .details {
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  overflow: hidden;
}
#message .message-container li .details .left {
  float: left;
  font-weight: 600;
  color: #888;
  transition-duration: .3s;
}
#message .message-container li .details .left .arrow {
  display: inline-block;
  position: relative;
  height: 2px;
  width: 20px;
  background: rgba(0, 0, 0, 0.15);
  vertical-align: top;
  margin: 12px 20px 0 15px;
  border: 0px solid rgba(0, 0, 0, 0.15);
  transition-duration: .3s;
}
#message .message-container li .details .left .arrow:after {
  position: absolute;
  top: -4px;
  left: 100%;
  height: 0;
  width: 0;
  border: inherit;
  border-width: 7px;
  border-style: solid;
  content: '';
  border-right: 0;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-top-width: 5px;
  border-bottom-width: 5px;
}
#message .message-container li .details .right {
  float: right;
  color: #999;
}
#message .message-container li .message {
  margin-bottom: 40px;
}
#message .message-container li .message p:last-child {
  margin-bottom: 0;
}
#message .message-container li:hover .tool-box .red-hover {
  color: #ff5b5b;
  border-color: #ff5b5b;
}
#message .message-container li:hover .tool-box .red-hover:hover {
  color: #f40000;
  border-color: #f40000;
}
#message .message-container li .tool-box {
  position: absolute;
  bottom: 0;
  right: 0;
  border: 0px solid #DDDFE1;
  border-top-width: 1px;
  border-left-width: 1px;
  padding: 8px 10px;
  transition-duration: .3s;
}
#message .message-container li .tool-box a {
  margin-right: 10px;
}
#message .message-container li .tool-box a:last-child {
  margin-right: 0;
}
#message .message-container li:hover .details .left .arrow {
  background: #10c469;
  border: 0px solid #10c469;
}
#message .message-container li:hover .details .left .arrow.orange {
  background: #f9c851;
  border: 0px solid #f9c851;
}
.show-message #message {
  transition-duration: .3s;
}
#message #message-nano-wrapper {
  position: absolute;
  top: 130px;
  bottom: 0;
  height: auto;
  left: 0;
  right: 0;
  width: auto;
}
.show-message #message {
  transform: none;
}
.show-message #main {
  margin-right: 40%;
}
@media only screen and (min-width: 1499px) {
  #main .overlay {
    display: none;
  }
}
@media only screen and (max-width: 1500px) {
  .show-message #main {
    margin-right: 0;
  }
  .show-message #message {
    left: 50%;
    width: 50%;
  }
}
@media only screen and (max-width: 1024px) {
  .show-sidebar #sidebar {
    transform: none;
  }
  .show-sidebar #main {
    transform: translateX(250px);
  }
  .show-message #main {
    margin-right: 0;
  }
}
@media only screen and (max-width: 768px) {
  #sidebar {
    transform: translateX(-100%);
  }
  #main {
    left: 0;
    box-shadow: none;
  }
  #main .header .page-title .sidebar-toggle-btn {
    margin-right: 20px;
    opacity: 1;
    width: 20px;
    margin-left: 10px;
  }
  #main .header .page-title .sidebar-toggle-btn .line {
    height: 2px;
  }
  .show-sidebar #sidebar {
    transform: none;
  }
  .show-sidebar #main {
    transform: translateX(250px);
  }
  .show-message #main {
    margin-right: 0;
  }
  .show-message #message {
    left: 20%;
    width: 80%;
  }
}
@media only screen and (max-width: 600px) {
  #main .header .search-box {
    float: none;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-left: 0px;
  }
  .action-bar ul {
    margin-bottom: 0px;
    text-align: center;
  }
  #main .header .search-box input,
  #main .header .search-box input:focus {
    width: 100%;
  }
  #main #main-nano-wrapper {
    position: absolute;
    top: 150px;
    bottom: 0;
    height: auto;
  }
  #main .message-list li .col-1 {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 120px;
    width: auto;
  }
  #main .message-list li .col-2 {
    right: 0;
    left: auto;
    width: 120px;
  }
  #main .message-list li .col-2 .date {
    padding-left: 0;
    position: static;
  }
  #main .message-list li .col-2 .subject {
    display: none;
  }
}
/**
 * Nano scroll stuff
*/
.nano {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.nano > .nano-content {
  position: absolute;
  overflow: scroll;
  overflow-x: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.nano > .nano-content:focus {
  outline: none;
}
.nano > .nano-content::-webkit-scrollbar {
  visibility: hidden;
}
.has-scrollbar > .nano-content::-webkit-scrollbar {
  visibility: visible;
}
.nano > .nano-pane {
  background: rgba(152, 166, 173, 0.1);
  position: absolute;
  width: 7px;
  right: 8px;
  top: 8px;
  bottom: 8px;
  visibility: hidden \9;
  /* Target only IE7 and IE8 with this hack */
  opacity: .01;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -webkit-transition: .3s;
  -moz-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}
.nano > .nano-pane > .nano-slider {
  background: rgba(152, 166, 173, 0.35);
  position: relative;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -webkit-transition: .3s;
  -moz-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  -webkit-transition-property: background;
  -moz-transition-property: background;
  -o-transition-property: background;
  transition-property: background;
}
.nano > .nano-pane:hover > .nano-slider,
.nano > .nano-pane.active > .nano-slider {
  background: #A6A6A6;
}
.nano:hover > .nano-pane,
.nano-pane.active,
.nano-pane.flashed {
  visibility: visible \9;
  /* Target only IE7 and IE8 with this hack */
  opacity: 0.99;
}
/* Profile */
.bg-picture {
  padding: 30px;
}
.profile-info-name img {
  width: 200px;
  float: left;
  margin-right: 30px;
}
.profile-info-detail {
  overflow: hidden;
}
.profile-pills li a {
  color: rgba(67, 89, 102, 0.5) !important;
  padding: 0px 10px;
  line-height: 30px !important;
}
.profile-pills li a i {
  font-size: 14px;
}
.comment {
  padding-top: 12px;
}
.comment .comment-avatar {
  position: relative;
  -webkit-border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px;
  -moz-background-clip: padding;
  border-radius: 3px;
  background-clip: padding-box;
  display: block;
  float: left;
  height: 32px;
  width: 32px;
}
.comment .comment-body {
  float: none;
  margin-left: 40px;
  position: relative;
}
.comment .comment-text {
  border: 1px solid #e5e5e5;
  border-radius: 2px;
  padding: 7px 12px 8px;
}
.comment .comment-header {
  font-size: 12px;
  padding-bottom: 2px;
}
.comment .comment-header a {
  color: #435966;
  font-weight: bold;
}
.comment .comment-header span {
  color: #98a6ad;
  display: inline-block;
  font-size: 11px;
  margin-left: 5px;
}
.comment .comment-footer {
  display: block;
  font-size: 12px;
  padding: 4px 12px 0;
}
.comment .comment-footer i {
  font-size: 13px;
  margin-right: 7px;
}
.comment .comment-footer,
.comment .comment-footer a {
  color: rgba(67, 89, 102, 0.5);
}
.comment .comment-footer:hover,
.comment .comment-footer a:hover {
  color: #71b6f9;
}
.comment > .comment {
  margin-left: 32px !important;
}
