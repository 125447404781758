/*
Template Name: Adminto Dashboard
Author: CoderThemes
Email: coderthemes@gmail.com
File: Responsive
*/
@media only screen and (max-width: 6000px) and (min-width: 700px) {
  .wrapper.right-bar-enabled .right-bar {
    right: 0;
    z-index: 99;
  }
}
@media (max-width: 1023px) {
  .button-menu-mobile {
    display: block !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  body {
    overflow-x: hidden;
  }
}
@media (max-width: 768px) {
  body {
    overflow-x: hidden;
  }
  .side-menu {
    position: absolute !important;
  }
  .enlarged .left.side-menu {
    margin-left: -75px;
  }
  .topbar-left {
    width: 70px !important;
  }
  .topbar-left span {
    display: none !important;
  }
  .topbar-left i {
    display: block !important;
    line-height: 75px !important;
  }
  .content-page .content {
    margin-top: 95px;
  }
  .topbar .topbar-left {
    height: 75px;
  }
  .navbar-default {
    background-color: #ffffff;
    box-shadow: 0 0px 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);
  }
  .navbar-nav {
    margin: 0px;
    display: inline-block;
  }
  .navbar-nav li {
    display: inline-block;
    line-height: 1px;
  }
  .navbar-nav.navbar-right {
    float: right;
  }
  .content-page {
    margin-left: 0px !important;
  }
  .footer {
    left: 0px !important;
  }
  .mobile-sidebar {
    left: 0px;
  }
  .mobile-content {
    left: 250px;
    right: -250px;
  }
  .wrapper-page {
    width: 90%;
  }
  .dataTables_wrapper .col-xs-6 {
    width: 100%;
    text-align: left;
  }
  div#datatable-buttons_info {
    float: none;
  }
  .ms-container {
    width: 100%;
  }
}
@media (max-width: 480px) {
  .side-menu {
    z-index: 10 !important;
  }
  .button-menu-mobile {
    display: block;
  }
  .search-bar {
    display: none !important;
  }
}
@media (max-width: 420px) {
  .hide-phone {
    display: none !important;
  }
}
/* Container-alt */
@media (min-width: 768px) {
  .container-alt {
    width: 750px;
  }
  .nav-tabs.nav-justified > li > a {
    border-bottom: 2px solid #eeeeee;
  }
}
@media (min-width: 992px) {
  .container-alt {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container-alt {
    width: 1170px;
  }
}
@media (max-width: 419px) {
  .topbar-left {
    width: 70px !important;
  }
  .logo .icon-c-logo {
    display: inline-block !important;
    line-height: 58px !important;
  }
  .logo span {
    display: none !important;
  }
  .wrapper-page .logo span {
    display: inline-block !important;
  }
  .content-page {
    margin-left: 70px;
  }
  .forced .side-menu.left {
    box-shadow: 0 12px 12px rgba(0, 0, 0, 0.1);
  }
  .enlarged .side-menu.left {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) !important;
  }
  .page-title {
    font-size: 15px;
    line-height: 67px;
    max-width: 250px;
    white-space: nowrap;
  }
  .navbar-default {
    padding: 0px;
  }
  .navbar-default .navbar-left {
    padding-left: 0px !important;
  }
  .navbar-default .navbar-left li {
    padding: 0px 5px;
  }
  .topbar-left {
    display: none;
  }
  .editable-responsive {
    overflow-x: auto;
  }
  .profile-info-name img {
    float: none;
    margin: 0px auto;
    margin-bottom: 20px;
  }
  div.hopscotch-bubble .hopscotch-bubble-container {
    max-width: 180px;
  }
}
